export const title = '売上報告を行う'
export const description = `売上報告は、必ずひとつの端末でご利用ください。
複数端末での同時利用はできません。

練習モードは、データ連携されません。
日々の売上報告を行う場合は、緑ボタンを押下して報告を行なってください。`

export const reportButtonText = '売上報告を開始する'
export const trainingButtonText = '練習モードを開始する'
export const serveryButtonText = 'アンケートに回答する'

export const reportButtonDescription = '売上報告を行う場合'
export const trainingButtonDescription = '練習モードを利用する場合'

export const bottomTitle = '売上報告歴'
export const bottomAnnotation = '(5営業日分)'

export const date = '売上日'
export const status = 'ステータス'
