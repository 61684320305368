import React, { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import useTenant, { useHistories } from '../hooks/useTenant'
import { salesDatePath } from '../../routes/paths'
import {
  enableResentMode,
  enableTrainingMode,
} from '../../features/mode/modeSlice'
import LargeTitleDescription from '../../components/molecules/LargeTitleDescription'
import styles from './style.module.scss'
import {
  bottomAnnotation,
  bottomTitle,
  date,
  description,
  reportButtonText,
  serveryButtonText,
  status,
  title,
  trainingButtonText,
  reportButtonDescription,
  trainingButtonDescription,
} from './message'
import Typography from '../../components/atoms/Typography'
import SalesDateRecord from '../../components/molecules/SalesDateRecord'
import AppHeader from '../../components/organisms/AppHeader'
import Button from '../../components/atoms/Button'
import { ReportStatusEnum } from '../../features/services/api'
import {
  clearSalesDate,
  setSalesDateId,
  updateSalesDate,
} from '../../features/network/salesDateSlice'
import useAppHistory from '../../utils/useAppHistory'
import { RootState } from '../../store'
import { getSurveyUrl } from '../../utils/surveyUrl'
import { VIEWABLE_SURVEY_ORG_CODE } from '../../models/org'
import Icon from '../../components/atoms/Icon'

function ReportCommand(): ReactElement {
  const tenant = useTenant()
  const { tenantDetail, orgCode } = useSelector(
    (state: RootState) => state.auth
  )
  const reportHistories = useHistories()
  const history = useAppHistory()
  const dispatch = useDispatch()

  const today = dayjs()
  const contractStartDate = tenantDetail?.contractStartDate
    ? dayjs(tenantDetail.contractStartDate)
    : undefined

  useEffect(() => {
    dispatch(clearSalesDate())
  }, [])

  const goToNextPage = (): void => {
    history.push(salesDatePath)
  }

  const handleClickReport = (): void => {
    goToNextPage()
  }

  const handleClickTraining = (): void => {
    dispatch(enableTrainingMode())
    goToNextPage()
  }

  const handleClickServery = (): void => {
    window.open(getSurveyUrl(tenant.storeName, tenant.tenantName))
  }

  const handleClickReportHistory = (
    id: string,
    reportStatus: ReportStatusEnum,
    selectedDate: string
  ): void => {
    const goToSelectDatePage = (): void => {
      const dateAsNumber = new Date(selectedDate).getTime()
      dispatch(updateSalesDate(dateAsNumber))
      goToNextPage()
    }

    switch (reportStatus) {
      case ReportStatusEnum.Waiting:
        goToSelectDatePage()
        break
      case ReportStatusEnum.Reported:
      case ReportStatusEnum.ImageResent:
        dispatch(enableResentMode())
        dispatch(setSalesDateId(id))
        goToSelectDatePage()
        break
      default:
        break
    }
  }

  const isOIOI = process.env.REACT_APP_OIOI_MODULE

  return (
    <div className={styles.root}>
      <AppHeader tenantName={tenant.tenantName} storeName={tenant.storeName} />
      <div className={styles.container}>
        {isOIOI && (
          <LargeTitleDescription
            className={styles.txt}
            title={title}
            description={description}
          />
        )}
        <div className={styles.buttonWrapper}>
          {!isOIOI && (
            <span className={styles.description}>
              {reportButtonDescription}
            </span>
          )}
          <Button
            className={styles.button}
            color="green"
            bold
            disabled={contractStartDate && today.isBefore(contractStartDate)}
            onClick={handleClickReport}
          >
            {reportButtonText}
          </Button>
          {contractStartDate && today.isBefore(contractStartDate) && (
            <div className={styles.alertWrapper}>
              <Icon className={styles.icon} icon="alert" />
              <Typography className={styles.alert} color="error">
                {`${dayjs(contractStartDate).format(
                  'YYYY/MM/DD'
                )}より利用可能になります`}
              </Typography>
            </div>
          )}
          {!isOIOI && (
            <>
              <div className={styles.divider} />
              <span className={styles.description}>
                {trainingButtonDescription}
              </span>
              <Button
                className={styles.button}
                color="blue"
                bold
                onClick={handleClickTraining}
              >
                {trainingButtonText}
              </Button>
            </>
          )}
          {orgCode && VIEWABLE_SURVEY_ORG_CODE.includes(orgCode) && (
            <div className={styles.anchorButton} onClick={handleClickServery}>
              {serveryButtonText}
            </div>
          )}
        </div>
      </div>
      {!isOIOI && reportHistories.length > 0 && (
        <div className={styles.bottom}>
          <div className={styles.bottomHeader}>
            <div className={styles.bottomTitle}>
              <Typography
                className={styles.title}
                variant="middleTitle"
                color="white"
              >
                {bottomTitle}
              </Typography>
              <Typography
                className={styles.annotation}
                variant="span"
                color="white"
              >
                {bottomAnnotation}
              </Typography>
            </div>
          </div>
          <div className={styles.reportHeader}>
            <Typography
              className={styles.headerColumn}
              variant="span"
              color="white"
            >
              {date}
            </Typography>
            <Typography
              className={styles.headerColumn}
              variant="span"
              color="white"
            >
              {status}
            </Typography>
          </div>
          <div className={styles.reportWrapper}>
            {reportHistories.map((report) => {
              return (
                <SalesDateRecord
                  className={styles.report}
                  key={report.id}
                  date={report.date}
                  reportedAt={
                    report.updatedAt !== null ? report.updatedAt : undefined
                  }
                  status={report.reportStatus}
                  onClick={(): void =>
                    handleClickReportHistory(
                      report.id,
                      report.reportStatus,
                      report.date
                    )
                  }
                />
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default ReportCommand
